import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="h1" components={components}>{`Hey! I'm Ayomide 👋`}</MDXTag>
      <MDXTag name="p" components={components}>{`I am a founder and software engineer. I am from `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://en.wikipedia.org/wiki/Nigeria"
        }}>{`Nigeria 🇳🇬, West Africa`}</MDXTag>{`. I studied Zoology in 2011 and dropped out after my first year to read Electronic and Electrical Engineering in 2012 in `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://oauife.edu.ng/"
        }}>{`Obafemi Awolowo University (OAU)`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`I started my career in software engineering late 2012 when I took on some `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.youtube.com/user/thenewboston"
        }}>{`youtube videos by Bucky`}</MDXTag>{` on HTML, CSS, Javascript and Java. Thanks Bucky 🙏. With the compounded yet sneezy knowledge I had I was able to get into a robotics club by presenting a replica of OAU's website on localhost using `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.httrack.com/"
        }}>{`HTTrack`}</MDXTag>{`. From that moment, I realized that I can definitely build a career in software engineering, I was inspired.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Fast forward to 2018, 6 years after college (should be 5, thanks OAU) I graduated with a Bachelor's degree in Electronic and Electrical Engineering from OAU, I decided to become a founder full-time. I cofounded `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://inventech.ng"
        }}>{`InvenTech`}</MDXTag>{`, launched `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://inventone.ng"
        }}>{`Invent One`}</MDXTag>{` and currently working on `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://etrack.africa"
        }}>{`ETrack`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`So far I have worked for `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://mettalloids.com"
        }}>{`Mettalloids Limited`}</MDXTag>{`, `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://konga.com"
        }}>{`Konga Online Shopping Limited`}</MDXTag>{`, `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://inventech.ng"
        }}>{`InvenTech`}</MDXTag>{` and other `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "_",
          "title": "Non existing"
        }}>{`"Freelance jobs"`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="h4" components={components}>{`Appreciation`}</MDXTag>
      <MDXTag name="p" components={components}>{`My achievment is largely due to family, friends, colleagues and the opensource movement.
My special appreciation goes to `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://opencollective.com/"
        }}>{`Everything Open Source`}</MDXTag>{`, `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://ieee.org"
        }}>{`IEEE`}</MDXTag>{`, `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://google.com"
        }}>{`Google`}</MDXTag>{` (groups and programs - GDG, ALCwithGoogle, etc), `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://udacity.com"
        }}>{`Udacity`}</MDXTag>{`, `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://coursera.org"
        }}>{`Coursera`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`And most important, to friends, colleagues and family that've in anyway made me possible, 💓 you!`}</MDXTag>
      <MDXTag name="h4" components={components}>{`How you can reach me any time`}</MDXTag>
      <MDXTag name="p" components={components}>{`You can send an email`}</MDXTag>
      <MDXTag name="p" components={components}>{`📨 `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "mailto:poke@ayomidebakare.site"
        }}><MDXTag name="inlineCode" components={components} parentName="a">{`poke@ayomidebakare.site`}</MDXTag></MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`or on Twitter `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://twitter.com/mbao_01",
          "title": "@mbao_01"
        }}>{`@mbao_01`}</MDXTag></MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    